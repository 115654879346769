<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveConfig()"
        >
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                label="Entidad Financiera"
                :items="entFin"
                item-text="value"
                item-value="id"
                v-model="selectEntFin"
                :rules="rules.required"
                outlined
                clearable
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                label="Entidad Facturante"
                :items="entFac"
                item-text="value"
                item-value="id"
                v-model="selectEntFac"
                :rules="rules.required"
                outlined
                clearable
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4" class="py-0">
              <v-text-field
                outlined
                dense
                label="Public Key"
                autocomplete="off"
                :rules="
                  rules.required.concat([
                    rules.maxLength(keyText, 80),
                    rules.requiredTrim(keyText)
                  ])
                "
                v-model.trim="keyText"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="py-0">
              <v-text-field
                outlined
                dense
                label="Acces Token"
                v-model.trim="tokenText"
                autocomplete="off"
                :rules="
                  rules.required.concat([
                    rules.maxLength(tokenText, 80),
                    rules.requiredTrim(tokenText)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="py-0">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelectedTextfield"
                    autocomplete="off"
                    label="Fecha de baja"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    v-on="on"
                    @blur="
                      fechaSelected = parseDateToIso(fechaSelectedTextfield)
                    "
                    outlined
                    dense
                    clearable
                    :rules="fechaSelectedTextfield ? rules.validDate : []"
                    hint="Formato DD/MM/AAAA"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  v-model="fechaSelected"
                  @input="menu = false"
                  @change="fechaSelectedTextfield = formatDate(fechaSelected)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" class="py-0">
              <v-select
                outlined
                clearable
                dense
                multiple
                v-model="selectPaymentTypes"
                label="Tipos de pagos excluidos"
                :items="paymentTypes"
                item-text="value"
                item-value="id"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ selectPaymentTypes.length - 1 }} otros)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="py-0">
              <v-select
                outlined
                clearable
                dense
                multiple
                v-model="selectPaymentMethods"
                label="Métodos de pagos excluidos"
                :items="paymentMethods"
                item-text="value"
                item-value="id"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ selectPaymentMethods.length - 1 }} otros)
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn outlined @click="closeModal">Cancelar</v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";

export default {
  props: {
    mpconfig: {
      type: Object,
      required: false
    }
  },
  name: "MercadoPagoModal",
  data: vm => ({
    fechaSelected: null,
    fechaSelectedTextfield: null,
    calendarIcon: enums.icons.CALENDAR,
    menu: false,
    rules: rules,
    tokenText: "",
    keyText: "",
    entFac: [],
    entFin: [],
    isFormValid: false,
    formTitle: "Editar configuración",
    selectEntFin: null,
    selectEntFac: null,
    selectPaymentMethods: null,
    selectPaymentTypes: null,
    paymentMethods: [],
    paymentTypes: []
  }),
  created() {
    if (this.mpconfig != null) {
      this.setConfig();
    } else {
      this.newConfig();
    }
    this.loadParameters();
  },
  methods: {
    ...mapActions({
      getEntFin: "configAppBenef/getEntidadesFinancieras",
      getEntFac: "configAppBenef/getEntidadesFacturantes",
      getPaymentMethods: "configAppBenef/getPaymentMethods",
      getPaymentTypes: "configAppBenef/getPaymentTypes",
      getConfigById: "configAppBenef/getConfigMercadoPagoById",
      postConfig: "configAppBenef/postConfigMercadoPago",
      setAlert: "user/setAlert"
    }),
    async loadParameters() {
      this.entFin = await this.getEntFin();
      this.entFac = await this.getEntFac();
      this.paymentMethods = await this.getPaymentMethods();
      this.paymentTypes = await this.getPaymentTypes();
      this.fechaSelectedTextfield = this.formatDate(this.fechaSelected);
    },
    async saveConfig() {
      const data = {
        mpconfigId: this.mpconfig != null ? this.mpconfig.mpconfigId : null,
        entFinId: this.selectEntFin,
        entFacId: this.selectEntFac,
        publicKey: this.keyText,
        accessToken: this.tokenText,
        fechaBaja: this.fechaSelected,
        excludedPaymentMethods: this.selectPaymentMethods,
        excludedPaymentTypes: this.selectPaymentTypes
      };
      const response = await this.postConfig(data);
      if (response.status === 200)
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      this.closeModal();
    },
    async setConfig() {
      const response = await this.getConfigById(this.mpconfig.mpconfigId);
      const data = response.data;
      this.selectEntFin = data.entFinId;
      this.selectEntFac = data.entFacId;
      this.keyText = data.publicKey;
      this.tokenText = data.accessToken;
      this.selectPaymentMethods = data.excludedPaymentMethods;
      this.selectPaymentTypes = data.excludedPaymentTypes;
      this.fechaSelectedTextfield = data.fechaBaja;
      this.fechaSelected = this.parseDateToIso(this.fechaSelectedTextfield);
    },
    async newConfig() {
      this.formTitle = "Nueva configuración";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
