var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveConfig()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-autocomplete',{attrs:{"label":"Entidad facturante","items":_vm.entidadesFacturantes,"item-text":"value","item-value":"id","rules":_vm.rules.required,"outlined":"","clearable":"","dense":""},model:{value:(_vm.entidadFacturanteSelected),callback:function ($$v) {_vm.entidadFacturanteSelected=$$v},expression:"entidadFacturanteSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-autocomplete',{attrs:{"label":"Entidad financiera","items":_vm.entidadesFinancieras,"item-text":"value","item-value":"id","rules":_vm.entidadFinancieraSelected != 0 ? _vm.rules.required : [],"outlined":"","clearable":"","dense":""},model:{value:(_vm.entidadFinancieraSelected),callback:function ($$v) {_vm.entidadFinancieraSelected=$$v},expression:"entidadFinancieraSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"menu-fechaDesde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"hint":"formato DD/MM/AAAA","clearable":"","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","dense":"","outlined":"","autocomplete":"off","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaDesdeSelectedTextfield)) <=
                        new Date(
                          _vm.parseDateToIso(_vm.fechaDesdeSelectedTextfield)
                        ) || 'Formato incorrecto'
                    )},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuFechaDesde = false},"blur":function($event){[
                      (_vm.fechaDesdeSelected = _vm.parseDateToIso(
                        _vm.fechaDesdeSelectedTextfield
                      )) ]},"change":function($event){[
                      (_vm.fechaDesdeSelected = _vm.parseDateToIso(
                        _vm.fechaDesdeSelectedTextfield
                      )) ]}},model:{value:(_vm.fechaDesdeSelectedTextfield),callback:function ($$v) {_vm.fechaDesdeSelectedTextfield=$$v},expression:"fechaDesdeSelectedTextfield"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaDesdeSelectedTextfield = _vm.formatDate(_vm.fechaDesdeSelected)},"input":function($event){_vm.menuFechaDesde = false}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"menu-fechaHasta",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"clearable":"","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","hint":"formato DD/MM/AAAA","dense":"","rules":_vm.rules.validDate.concat(
                      new Date(_vm.parseDateToIso(_vm.fechaDesdeSelectedTextfield)) <=
                        new Date(
                          _vm.parseDateToIso(_vm.fechaHastaSelectedTextfield)
                        ) || 'Formato incorrecto'
                    ),"outlined":"","autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuHasta = false},"blur":function($event){[
                      (_vm.fechaHastaSelected = _vm.parseDateToIso(
                        _vm.fechaHastaSelectedTextfield
                      )) ]},"change":function($event){[
                      (_vm.fechaHastaSelected = _vm.parseDateToIso(
                        _vm.fechaHastaSelectedTextfield
                      )) ]}},model:{value:(_vm.fechaHastaSelectedTextfield),callback:function ($$v) {_vm.fechaHastaSelectedTextfield=$$v},expression:"fechaHastaSelectedTextfield"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaHastaSelectedTextfield = _vm.formatDate(_vm.fechaHastaSelected)},"input":function($event){_vm.menuFechaHasta = false}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"outlined":"","dense":"","hide-default-footer":"","header-props":{ sortIcon: null },"items-per-page":50,"headers":_vm.configs,"item-key":"bpcdId","items":_vm.detalleConfig,"fixed-header":""},scopedSlots:_vm._u([{key:"item.dato",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.dato)+" "),_c('v-icon',{attrs:{"color":'primary',"size":"16"},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(_vm._s(_vm.editIcon))])],1)]}}],null,true)},[_c('span',[_vm._v("Editar valor")])])]}}],null,true)}),(_vm.openModalEditValor)?_c('v-dialog',{attrs:{"max-width":"400px","persistent":""},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveEditValor($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancelEditValor($event)}]},model:{value:(_vm.openModalEditValor),callback:function ($$v) {_vm.openModalEditValor=$$v},expression:"openModalEditValor"}},[_c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"headline primary--text py-0"},[_vm._v(" Editar valor ")]),_c('v-card-text',{staticClass:"py-0"},[_c('v-text-field',{ref:"edit-valor",attrs:{"single-line":"","label":"Editar valor"},model:{value:(_vm.campoValorToEdit),callback:function ($$v) {_vm.campoValorToEdit=$$v},expression:"campoValorToEdit"}})],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.cancelEditValor}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveEditValor}},[_vm._v(" Guardar ")])],1)],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }