<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-data-table
          :headers="headers"
          :items="configuracionDePago"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Medio de pago:</v-toolbar-title>
              <v-row class="pt-5">
                <v-col cols="3" class="pl-5">
                  <v-select
                    v-model="botonDePagoSelected"
                    :items="botonesDePago"
                    item-text="value"
                    item-value="id"
                    label="Seleccione"
                    outlined
                    required
                    @change="getConfiguracionesBotonDePago()"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="7" class="pt-4">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn
                    color="primary"
                    @click="openModal()"
                    class="to-right"
                    :disabled="!botonDePagoSelected"
                  >
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar configuración</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteConfig(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar configuración</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog
          v-if="openModalEditMercadoPago"
          v-model="openModalEditMercadoPago"
          max-width="60%"
          @keydown.esc="closeAndReload"
          persistent
        >
          <ModalMercadoPago
            :mpconfig="mpconfig"
            @closeAndReload="closeAndReload"
          />
        </v-dialog>
        <v-dialog
          v-if="openModalEditSiroBotonDePago"
          v-model="openModalEditSiroBotonDePago"
          max-width="60%"
          @keydown.esc="closeAndReload"
          persistent
        >
          <SIROBotonDePagoModal
            :siroConfig="siroConfig"
            :botonDePagoSelected="botonDePagoSelected"
            @closeAndReload="closeAndReload"
          />
        </v-dialog>
        <DeleteDialog
          :titleProp="titleDelete"
          :openDelete.sync="showDeleteModal"
          @onDeleteItem="confirmDelete()"
        />
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModalMercadoPago from "@/components/modules/appBenef/configuracion/MercadoPagoModal";
import SIROBotonDePagoModal from "@/components/modules/appBenef/configuracion/SIROBotonDePagoModal";
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda,
    ModalMercadoPago,
    SIROBotonDePagoModal
  },
  name: "BotonDePago",
  data() {
    return {
      optionCode: enums.webSiteOptions.BOTON_DE_PAGO,
      title: enums.titles.BOTON_DE_PAGO,
      showExpand: false,
      showHelp: false,
      showIcon: true,
      routeToGo: "ConfiguracionAppBenef",
      headers: [
        {
          text: "Entidad facturante",
          align: "start",
          value: "entFacNombre"
        },
        {
          text: "Entidad financiera",
          align: "start",
          value: "entFinNombre"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      configuracionDePago: [],
      searchIcon: enums.icons.SEARCH,
      search: "",
      isLoading: false,
      allowedActions: null,
      canEdit: false,
      canDelete: false,
      canCreate: false,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      titleDelete: "¿Eliminar registro?",
      showDeleteModal: false,
      botonDePagoSelected: null,
      botonesDePago: [],
      openModalEditMercadoPago: false,
      mpconfig: null,
      openModalEditSiroBotonDePago: false,
      siroConfig: false,
      medioPagoSelected: null
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setBotonesDePago();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getBotonesDePago: "configAppBenef/getBotonesDePago",
      getConfigMercadoPago: "configAppBenef/getConfigMercadoPago",
      getConfiguracionesDePago: "configAppBenef/getConfiguracionesDePago",
      deleteConfigMercadoPago: "configAppBenef/deleteConfigMercadoPago",
      deleteConfiguracionDePago: "configAppBenef/deleteConfiguracionDePago",
      setAlert: "user/setAlert"
    }),
    async setBotonesDePago() {
      const botonesPago = await this.getBotonesDePago();
      this.botonesDePago = botonesPago;
    },
    async getConfiguracionesBotonDePago() {
      this.isLoading = true;
      switch (this.botonDePagoSelected) {
        case 1:
          this.configuracionDePago = await this.getConfiguracionesDePago(
            this.botonDePagoSelected
          );
          this.medioPagoSelected = 1;
          this.isLoading = false;
          break;
        case 2:
          this.configuracionDePago = await this.getConfigMercadoPago();
          this.isLoading = false;
          break;
        case 3:
          this.configuracionDePago = await this.getConfiguracionesDePago(
            this.botonDePagoSelected
          );
          this.medioPagoSelected = 3;
          this.isLoading = false;
          break;
        default:
          break;
      }
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_CONFIG_PAGO:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_CONFIG_PAGO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_CONFIG_PAGO:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    openModal(item) {
      switch (this.botonDePagoSelected) {
        case 1:
          this.openModalEditSiroBotonDePago = true;
          this.siroConfig = item;
          break;
        case 2:
          this.openModalEditMercadoPago = true;
          this.mpconfig = item;
          break;
        case 3:
          this.openModalEditSiroBotonDePago = true;
          this.siroConfig = item;
          break;
        default:
          break;
      }
    },
    closeAndReload() {
      switch (this.botonDePagoSelected) {
        case 1:
          this.openModalEditSiroBotonDePago = false;
          break;
        case 2:
          this.openModalEditMercadoPago = false;
          break;
        case 3:
          this.openModalEditSiroBotonDePago = false;
          break;
        default:
          break;
      }
      this.getConfiguracionesBotonDePago();
    },
    deleteConfig(item) {
      this.showDeleteModal = true;
      switch (this.botonDePagoSelected) {
        case 1:
          this.idToDelete = item.id;
          break;
        case 2:
          this.idToDelete = item.mpconfigId;
          break;
        case 3:
          this.idToDelete = item.id;
          break;
        default:
          break;
      }
    },
    async confirmDelete() {
      switch (this.botonDePagoSelected) {
        case 1:
          const deleteSIRO = await this.deleteConfiguracionDePago({
            id: this.idToDelete
          });
          if (deleteSIRO.status === 200) {
            this.showDeleteModal = false;
            this.setAlert({ type: "success", message: "Eliminado con éxito." });
          }
          break;
        case 2:
          const deleteMercadoPago = await this.deleteConfigMercadoPago({
            mpconfigId: this.idToDelete
          });
          if (deleteMercadoPago.status === 200) {
            this.showDeleteModal = false;
            this.setAlert({ type: "success", message: "Eliminado con éxito." });
          }
          break;
          case 3:
          const deletePlusPagos = await this.deleteConfiguracionDePago({
            id: this.idToDelete
          });
          if (deletePlusPagos.status === 200) {
            this.showDeleteModal = false;
            this.setAlert({ type: "success", message: "Eliminado con éxito." });
          }
          break;
        default:
          break;
      }
      this.getConfiguracionesBotonDePago();
    }
  }
};
</script>

<style></style>
