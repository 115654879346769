<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveConfig()"
        >
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-autocomplete
                label="Entidad facturante"
                :items="entidadesFacturantes"
                item-text="value"
                item-value="id"
                v-model="entidadFacturanteSelected"
                :rules="rules.required"
                outlined
                clearable
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-autocomplete
                label="Entidad financiera"
                :items="entidadesFinancieras"
                item-text="value"
                item-value="id"
                v-model="entidadFinancieraSelected"
                :rules="entidadFinancieraSelected != 0 ? rules.required : []"
                outlined
                clearable
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="py-0">
              <v-menu
                ref="menu-fechaDesde"
                v-model="menuFechaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeSelectedTextfield"
                    label="Fecha desde"
                    :append-icon="calendarIcon"
                    v-mask="'##/##/####'"
                    hint="formato DD/MM/AAAA"
                    clearable
                    @keydown.tab="menuFechaDesde = false"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="
                      [
                        (fechaDesdeSelected = parseDateToIso(
                          fechaDesdeSelectedTextfield
                        )),
                      ]
                    "
                    @change="
                      [
                        (fechaDesdeSelected = parseDateToIso(
                          fechaDesdeSelectedTextfield
                        )),
                      ]
                    "
                    dense
                    outlined
                    autocomplete="off"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaDesdeSelectedTextfield)) <=
                          new Date(
                            parseDateToIso(fechaDesdeSelectedTextfield)
                          ) || 'Formato incorrecto'
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesdeSelected"
                  no-title
                  scrollable
                  @change="
                    fechaDesdeSelectedTextfield = formatDate(fechaDesdeSelected)
                  "
                  @input="menuFechaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="py-0">
              <v-menu
                ref="menu-fechaHasta"
                v-model="menuFechaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaHastaSelectedTextfield"
                    label="Fecha hasta"
                    :append-icon="calendarIcon"
                    @keydown.tab="menuHasta = false"
                    v-mask="'##/##/####'"
                    clearable
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    hint="formato DD/MM/AAAA"
                    @blur="
                      [
                        (fechaHastaSelected = parseDateToIso(
                          fechaHastaSelectedTextfield
                        )),
                      ]
                    "
                    @change="
                      [
                        (fechaHastaSelected = parseDateToIso(
                          fechaHastaSelectedTextfield
                        )),
                      ]
                    "
                    dense
                    :rules="
                      rules.validDate.concat(
                        new Date(parseDateToIso(fechaDesdeSelectedTextfield)) <=
                          new Date(
                            parseDateToIso(fechaHastaSelectedTextfield)
                          ) || 'Formato incorrecto'
                      )
                    "
                    outlined
                    autocomplete="off"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHastaSelected"
                  no-title
                  scrollable
                  @change="
                    fechaHastaSelectedTextfield = formatDate(fechaHastaSelected)
                  "
                  @input="menuFechaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                outlined
                dense
                hide-default-footer
                :header-props="{ sortIcon: null }"
                :items-per-page="50"
                :headers="configs"
                item-key="bpcdId"
                class="elevation-1"
                :items="detalleConfig"
                fixed-header
              >
                <template v-slot:[`item.dato`]="{ item }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        {{ item.dato }}
                        <v-icon
                          :color="'primary'"
                          size="16"
                          @click="openModal(item)"
                          >{{ editIcon }}</v-icon
                        >
                      </div>
                    </template>
                    <span>Editar valor</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-dialog
                v-if="openModalEditValor"
                v-model="openModalEditValor"
                max-width="400px"
                @keydown.enter="saveEditValor"
                persistent
                @keydown.esc="cancelEditValor"
              >
                <v-card>
                  <v-container>
                    <v-card-title class="headline primary--text py-0">
                      Editar valor
                    </v-card-title>
                    <v-card-text class="py-0">
                      <v-text-field
                        ref="edit-valor"
                        v-model="campoValorToEdit"
                        single-line
                        label="Editar valor"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="cancelEditValor">
                        Cancelar
                      </v-btn>
                      <v-btn color="primary" @click="saveEditValor">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn outlined @click="closeModal">Cancelar</v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  props: {
    siroConfig: {
      type: Object,
      required: false,
    },
    botonDePagoSelected: {
      type: Number,
      required: true,
    },
  },
  directives: { mask },
  name: "SIROBotonDePagoModal",
  data: (vm) => ({
    fechaDesdeSelected: null,
    fechaHastaSelected: null,
    fechaDesdeSelectedTextfield: null,
    fechaHastaSelectedTextfield: null,
    calendarIcon: enums.icons.CALENDAR,
    editIcon: enums.icons.EDIT,
    menuFechaDesde: false,
    menuFechaHasta: false,
    selectValor: [],
    rules: rules,
    entidadesFacturantes: [],
    entidadesFinancieras: [],
    isFormValid: false,
    formTitle: "Editar configuración",
    entidadFacturanteSelected: null,
    entidadFinancieraSelected: null,
    medioDePagoSelected: null,
    openModalEditValor: false,
    campoValorToEdit: null,
    configs: [
      {
        text: "Tipo",
        align: "start",
        value: "tipoDato",
        sortable: false,
      },
      { text: "Valor", value: "dato", sortable: false },
      { text: "Descripción", value: "tipoDatoDescrip", sortable: false },
    ],
    detalleConfig: [],
    idToEdit: null,
    variablesToSend: [],
  }),
  created() {
    if (this.siroConfig != null) {
      this.setConfig();
    } else {
      this.newConfig();
      this.setTableVariables();
    }
    this.setSelects();
  },
  methods: {
    ...mapActions({
      getEntidadesFinancieras: "configAppBenef/getEntidadesFinancieras",
      getEntidadesFacturantes: "configAppBenef/getEntidadesFacturantes",
      getMedioDePagoDetalleById: "configAppBenef/getMedioDePagoDetalleById",
      getMedioDePagoById: "configAppBenef/getMedioDePagoById",
      saveMedioDePago: "configAppBenef/saveMedioDePago",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      this.entidadesFinancieras = await this.getEntidadesFinancieras();
      this.entidadesFacturantes = await this.getEntidadesFacturantes();
    },
    async setTableVariables() {
      this.detalleConfig = await this.getMedioDePagoDetalleById(
        this.botonDePagoSelected
      );
    },
    openModal(item) {
      this.campoValorToEdit = item.dato;
      this.previousValue = item.dato;
      this.idToEdit = item.bpcdId;
      this.openModalEditValor = true;
    },
    cancelEditValor() {
      const indice = this.detalleConfig.findIndex(
        (x) => x.bpcdId === this.idToEdit
      );
      const result = this.detalleConfig.find((x) => x.bpcdId === this.idToEdit);
      result["dato"] = this.previousValue;
      this.detalleConfig.splice(indice, 1, result);
      this.openModalEditValor = false;
    },
    saveEditValor() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const indice = this.detalleConfig.findIndex(
          (x) => x.bpcdId === this.idToEdit
        );
        const result = this.detalleConfig.find(
          (x) => x.bpcdId === this.idToEdit
        );
        result["dato"] = this.campoValorToEdit;
        this.detalleConfig.splice(indice, 1, result);
        this.openModalEditValor = false;
        this.setAlert({ type: "success", message: "Actualizado con éxito" });
      }, 100);
    },
    async saveConfig() {
      this.variablesToSend = [];
      this.detalleConfig.forEach((x) => {
        const variables = {
          tipoDato: x.tipoDato,
          dato: x.dato,
          tipoDatoDescrip: x.tipoDatoDescrip,
        };
        this.variablesToSend.push(variables);
      });
      const data = {
        bpcId: this.siroConfig != null ? this.siroConfig.id : 0,
        bpId: this.botonDePagoSelected,
        entFacId: this.entidadFacturanteSelected,
        entFinId: this.entidadFinancieraSelected,
        fechaDesde: this.fechaDesdeSelected,
        fechaHasta: this.fechaHastaSelected,
        variables: this.variablesToSend,
      };
      const response = await this.saveMedioDePago(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    async setConfig() {
      const response = await this.getMedioDePagoById({
        bpcId: this.siroConfig.id,
      });
      this.entidadFinancieraSelected = response.entFinId;
      this.entidadFacturanteSelected = response.entFacId;
      this.detalleConfig = response.variables;
      this.fechaDesdeSelectedTextfield = response.fechaDesde;
      this.fechaDesdeSelected = this.parseDateToIso(response.fechaDesde);
      this.fechaHastaSelectedTextfield = response.fechaHasta;
      this.fechaHastaSelected = this.parseDateToIso(
        this.fechaHastaSelectedTextfield
      );
      this.detalleConfig = response.variables;
    },
    async newConfig() {
      this.formTitle = "Nueva configuración";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>
